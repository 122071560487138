
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';

import UsersModule from '@/store/settings/users';
import UserEntityModule from '@/store/settings/users/entity';

@Component({
  components: {
    TitleReturn,
    FormBlock,
  },
})
export default class UserEntityAdd extends Form {
  get titlePage() {
    return UsersModule.pageSettings.titleAdd;
  }

  returnBefore() {
    this.$router.push({ name: 'users' });
  }

  mounted() {
    UserEntityModule.resetModel();
  }
}
